import browserHistory from 'react-router/lib/browserHistory'
import {cart, fetchCartWithProductsAndShippingFromApi} from './cart'
import {createActions} from '../../../redux'
import {eqQuery} from '../../helpers'

export const address = createActions('address')

/**
 * @param {App.Vintage.Carts.Address} body
 * @returns {import('redux-thunk').ThunkAction<Promise<App.Actions.ApiActionResponse<App.Vintage.Carts.Address>>>}
 */
export const createAddress =
  (body) =>
  /**
   * @type {import('redux-thunk').ThunkAction<Promise<App.Actions.ApiActionResponse<App.Vintage.Carts.Address>>>}
   */
  (dispatch) => {
    if (body && body.country) {
      body.country_id = body.country.id
      delete body.country
    }

    return dispatch(address.create({options: {body}}))
  }

/**
 *
 * @param {number} cartId
 * @param {App.Vintage.Carts.Address} body
 * @param {boolean} [noRedirect=false]
 * @returns {import('redux-thunk').ThunkAction<Promise<unknown>>}
 */
export const createShippingAddress =
  (cartId, body, noRedirect = false) =>
  /** @type {import('redux-thunk').ThunkAction<Promise<unknown>>} */
  (dispatch) =>
    dispatch(createAddress(body)).then(
      /** @param {App.Actions.ApiActionResponse<App.Vintage.Carts.Address>} param */
      ({body: [address] = []}) => {
        if (address) {
          return dispatch(
            cart.update({
              query: {id: `eq.${cartId}`},
              options: {
                body: {
                  billing_address_id: address.id,
                  shipping_address_id: address.id
                }
              },
              merge: {id: cartId}
            })
          )
            .then(() => dispatch(fetchCartWithProductsAndShippingFromApi()))
            .then(() => {
              if (!noRedirect) {
                browserHistory.push('/checkout/payment')
              }
            })
        }
      }
    )

export const setBillingAddress = (cartId, addressId) => (dispatch) =>
  dispatch(
    cart.update({
      query: {id: `eq.${cartId}`},
      options: {body: {billing_address_id: addressId}},
      merge: {id: cartId}
    })
  )

/**
 *
 * @param {number} id
 * @param {App.Vintage.Carts.Address} body
 * @returns {import('redux-thunk').ThunkAction<Promise<App.Actions.ApiActionResponse<App.Vintage.Carts.Address>>>}
 */
export const updateAddress =
  (id, body) =>
  /**
   * @type {import('redux-thunk').ThunkAction<Promise<App.Actions.ApiActionResponse<App.Vintage.Carts.Address>>>}
   */
  (dispatch) => {
    if (body && body.country) {
      body.country_id = body.country.id
      delete body.country
    }

    return dispatch(
      address.update({query: eqQuery({id}), options: {body}, merge: {id}})
    )
  }

/**
 * @param {number} id
 * @param {App.Vintage.Carts.Address} [data={}]
 * @param {boolean} [noRedirect=false]
 * @returns {import('redux-thunk').ThunkAction<Promise<unknown>>}
 */
export const updateShippingAddress =
  (id, data = {}, noRedirect = false) =>
  (dispatch) =>
    dispatch(updateAddress(id, data)).then(
      /** @param {App.Actions.ApiActionResponse<App.Vintage.Carts.Address>} param */
      ({body: addresses = [], err}) => {
        if (addresses.length || !err) {
          return dispatch(fetchCartWithProductsAndShippingFromApi()).then(
            () => {
              if (!noRedirect) {
                browserHistory.push('/checkout/payment')
              }
            }
          )
        }
      }
    )
