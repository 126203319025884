// @ts-check
import * as labels from '../../../../shared/labels'
import {itemKey, sortAndGroup} from '@/shared/helpers/item'

/**
 * @param {Object} param
 * @param {App.Vintage.Synthetic.Order} [param.order]
 * @param {App.Vintage.Synthetic.Cart} [param.cart]
 * @param {(App.Vintage.Carts.Product | App.Vintage.Orders.Product)} [param.product]
 * @param {(App.Vintage.Carts.Asset | App.Vintage.Orders.Asset)} [param.asset]
 * @param {(App.Vintage.Carts.AssetVariation | App.Vintage.Orders.AssetVariation)} [param.variation]
 * @param {(App.Vintage.Carts.AssetVariations | App.Vintage.Orders.AssetVariations)} [param.variations]
 * @param {App.Vintage.Base.Discount} [param.discount]
 * @param {(App.Vintage.Carts.Sku | App.Vintage.Orders.Sku)} [param.sku]
 * @param {number} [param.quantity]
 * @returns {App.Attentive.AttentiveEvent}
 */
export const generateAttentiveEventData = ({
  order,
  cart,
  product,
  asset,
  variation,
  variations,
  discount,
  sku,
  quantity
}) => {
  /** @type App.Attentive.AttentiveItem[] */
  const items = []
  /** @type App.Attentive.AttentiveOrder */
  let attentiveOrder
  /** @type App.Attentive.AttentiveCart */
  let attentiveCart
  /** @type App.Attentive.User */
  let user
  if (order) {
    // Order

    const {grouped} = sortAndGroup(order.items)

    // Attentive Items
    order.items.forEach((orderItem) => {
      const group = grouped[itemKey(orderItem)]
      /** @type number */
      const orderQuantity = group.length

      const item = items.find((i) => i.key === itemKey(orderItem))
      if (item) {
        item.quantity = orderQuantity
      } else {
        const asset = orderItem.asset_variations?.asset
        const name = labels.product(asset, orderItem.product?.product_group)
        items.push({
          key: itemKey(orderItem),
          productId: orderItem.product?.id.toString(),
          productVariantId: orderItem.asset_variation?.id.toString(),
          name,
          price: {value: orderItem.product?.price, currency: 'USD'},
          quantity: orderQuantity
        })
      }
    })

    // Attentive Order
    attentiveOrder = {
      orderId: order.id
    }

    // Attentive Cart
    if (cart) {
      attentiveCart = {
        cartId: cart.cartId || cart.id
      }
      if (order.discounts.length > 0) {
        attentiveCart.cartCoupon = order.discounts[0]?.code
      } else if (discount) {
        attentiveCart.cartCoupon = discount.code
      }
    }

    // Attentive User
    user = {
      phone:
        order.billingAddress?.phone_number ||
        order.shippingAddress?.phone_number,
      email: order.billingAddress?.email || order.shippingAddress?.email,
      externalIdentifiers: {
        clientUserId:
          order?.account_id ||
          order?.billingAddress?.account_id ||
          order?.shippingAddress?.account_id ||
          cart?.account_id ||
          cart?.billingAddress?.account_id ||
          cart?.shippingAddress?.account_id ||
          user?.externalIdentifiers?.clientUserId
      }
    }
  } else if (cart) {
    // Cart

    const {grouped} = sortAndGroup(cart.items)

    // Attentive Items
    cart.items.forEach((cartItem) => {
      const group = grouped[itemKey(cartItem)]
      const cartQuantity = group.length
      const item = items.find((i) => i.key === itemKey(cartItem))
      if (item) {
        item.quantity = cartQuantity
      } else {
        const asset = cartItem.asset_variations?.asset
        const name = labels.product(asset, cartItem.product?.product_group)

        items.push({
          key: itemKey(cartItem),
          productId: cartItem.product?.id.toString(),
          productVariantId: cartItem.asset_variation?.id.toString(),
          // productImage,
          // productUrl,
          name,
          price: {value: cartItem.product?.price, currency: 'USD'},
          quantity: cartQuantity
        })
      }
    })

    // Attentive Cart
    if (cart) {
      attentiveCart = {
        cartId: cart.id
      }
    }

    // Attentive User
    user = {
      phone:
        cart.billingAddress?.phone_number || cart.shippingAddress?.phone_number,
      email: cart.billingAddress?.email || cart.shippingAddress?.email,
      externalIdentifiers: {
        clientUserId:
          order?.account_id ||
          order?.billingAddress?.account_id ||
          order?.shippingAddress?.account_id ||
          cart?.account_id ||
          cart?.billingAddress?.account_id ||
          cart?.shippingAddress?.account_id ||
          user?.externalIdentifiers?.clientUserId
      }
    }
  }

  // Product
  if (product) {
    let item
    let cartQuantity
    let fakeCartItem
    if (product && sku && variation && variations) {
      /** @type App.Vintage.Synthetic.CartItem */
      fakeCartItem = {
        product,
        sku,
        asset_variation: variation,
        asset_variations: variations
      }
      /** @type App.Attentive.AttentiveItem */
      if (cart && cart.items) {
        const {grouped} = sortAndGroup([...cart.items, fakeCartItem])
        const group = grouped[itemKey(fakeCartItem)]
        cartQuantity = group.length
        item = items.find((i) => i.key === itemKey(fakeCartItem))
      }
    }

    if (item) {
      item.quantity = cartQuantity - 1 + quantity
    } else {
      const name = labels.product(asset, product.group)

      items.push({
        key: fakeCartItem ? itemKey(fakeCartItem) : undefined,
        productId: product.id.toString(),
        productVariantId: variation ? variation.id.toString() : undefined,
        // productImage,
        // productUrl,
        name,
        price: {value: product.price, currency: 'USD'},
        quantity: quantity
      })
    }
  }

  // Attentive Cart
  if (cart && !attentiveCart) {
    attentiveCart = {
      cartId: cart.cartId || cart.id
    }
  }

  if (attentiveCart && !attentiveCart.cartCoupon) {
    if (order?.discounts.length > 0 && order?.discounts[0].code) {
      attentiveCart.cartCoupon = order?.discounts?.[0].code
    } else if (discount?.code) {
      attentiveCart.cartCoupon = discount.code
    }
  }

  return {
    items,
    cart: attentiveCart,
    order: attentiveOrder,
    user,
    occurredAt: new Date().toISOString()
  }
}
